import { CAREER_NAME_MAP } from "../utils/constants";

const CareerIcon = ({ career }) => {
  let careerName = career;
  if (career in CAREER_NAME_MAP) {
    careerName = CAREER_NAME_MAP[career];
  }
  const icon = careerName.toLowerCase().replace("_", "-");

  return <img src={`/images/icons/${icon}.png`} />;
};

export default CareerIcon;
