import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { killsQuery } from "../queries/killsQuery";
import CareerIcon from "./career-icon";

function Snapshot() {
  const [allKills, setAllKills] = useState([]);
  const [endCursor, setEndCursor] = useState(null);
  const [loading, setLoading] = useState(true);

  // get kills param from url
  const urlParams = new URLSearchParams(window.location.search);
  const rkills = urlParams.get("kills");
  console.log("rkills", rkills);
  const requiredKills = rkills ? parseInt(rkills) : 500;
  console.log("requiredKills", requiredKills);

  const { error } = useQuery(killsQuery, {
    variables: {
      first: 50,
      after: endCursor,
      where: {
        scenarioId: {
          eq: 0,
        },
        and: [
          {
            zoneId: {
              neq: 6,
            },
          },
          {
            zoneId: {
              neq: 11,
            },
          },
          {
            zoneId: {
              neq: 100,
            },
          },
          {
            zoneId: {
              neq: 106,
            },
          },
          {
            zoneId: {
              neq: 200,
            },
          },
          {
            zoneId: {
              neq: 206,
            },
          },
        ],
      },
    },
    onCompleted: (data) => {
      //console.log("onCompleted", data);
      const _kills = data?.kills?.nodes;
      if (_kills != null) {
        // add to allKills array

        setAllKills([...allKills, ..._kills]);
      }
      const endCursor = data?.kills?.pageInfo?.endCursor;
      const timeNow = new Date().getTime();
      const halfHourAgo = timeNow - 1000 * 60 * 60;
      const latsetKillTime =
        new Date(_kills[_kills.length - 1].time).getTime() * 1000;

      const recentKill = latsetKillTime > halfHourAgo;

      if (endCursor != null && allKills.length < requiredKills && recentKill) {
        setEndCursor(endCursor);
      } else {
        setLoading(false);
      }
    },
  });

  if (error) {
    return <div>Error! Alt + F4!</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  //console.log("kills", allKills);

  // zone = kill.position.zone.name;
  // guild = kill.attackers[0].guild.name;
  // create dictionary of zone and kills per guild in each zone
  const zoneGuildCareer = {};
  const zoneKills = allKills.reduce((acc, kill) => {
    const zone = kill.position.zone.name;
    const guild = kill.attackers[0].guild;
    if (!guild) {
      return acc;
    }
    // concat guildName relam
    const guildName = `${guild.name}[${guild.realm}]`;
    if (!acc[zone]) {
      acc[zone] = {};
    }
    if (!acc[zone][guildName]) {
      acc[zone][guildName] = 0;
      //acc[zone][guild.name] = [];
    }
    acc[zone][guildName] += 1;
    //acc[zone][guild.name].push(career);
    const career = kill.attackers[0].character.career;
    //console.log("career", career);
    if (!zoneGuildCareer[zone]) {
      zoneGuildCareer[zone] = {};
    }
    if (!zoneGuildCareer[zone][guildName]) {
      zoneGuildCareer[zone][guildName] = [];
    }
    zoneGuildCareer[zone][guildName].push(kill.attackers[0].character);

    return acc;
  }, {});

  // sort zoneKills by most guild with most kills including value
  const sortedZoneKills = Object.entries(zoneKills).sort((a, b) => {
    const aKills = Object.values(a[1]).reduce((acc, val) => acc + val, 0);
    const bKills = Object.values(b[1]).reduce((acc, val) => acc + val, 0);
    return bKills - aKills;
  });

  /// for each sort by guild with most kills
  sortedZoneKills.forEach((zone) => {
    zone[1] = Object.entries(zone[1]).sort((a, b) => b[1] - a[1]);
  });

  //console.log("zoneKills", zoneGuildCareer);
  //  console.log("sortedZoneKills", sortedZoneKills);

  const getColor = (guild) => {
    if (guild[0].includes("[ORDER")) {
      return "text-blue-400";
    } else if (guild[0].includes("[DESTRUCTION")) {
      return "text-red-400";
    } else {
      return "text-gray-500";
    }
  };

  const stripRealm = (guild) => {
    return guild.split("[")[0];
  };

  const zone1TotalKills = sortedZoneKills[0][1].reduce(
    (acc, val) => acc + val[1],
    0
  );

  const zone2TotalKills =
    sortedZoneKills.length > 1 &&
    sortedZoneKills[1][1].reduce((acc, val) => acc + val[1], 0);

  const zone1OrderTotalKills = sortedZoneKills[0][1].reduce((acc, val) => {
    if (val[0].includes("[ORDER")) {
      return acc + val[1];
    } else {
      return acc;
    }
  }, 0);

  const zone1DestTotalKills = sortedZoneKills[0][1].reduce((acc, val) => {
    if (val[0].includes("[DESTRUCTION")) {
      return acc + val[1];
    } else {
      return acc;
    }
  }, 0);

  const zone2OrderTotalKills =
    sortedZoneKills.length > 1 &&
    sortedZoneKills[1][1].reduce((acc, val) => {
      if (val[0].includes("[ORDER")) {
        return acc + val[1];
      }
      return acc;
    }, 0);

  const zone2DestTotalKills =
    sortedZoneKills.length > 1 &&
    sortedZoneKills[1][1].reduce((acc, val) => {
      if (val[0].includes("[DESTRUCTION")) {
        return acc + val[1];
      }
      return acc;
    }, 0);

  const getGuildCareerCount = (zone, guild) => {
    const zoneData = zoneGuildCareer[zone];
    const guildData = zoneData[guild[0]];
    //console.log("zoneData", zoneData);
    //console.log("guildData", guildData);

    // get count of careers in guild
    const playerSet = new Set();
    const playerCareers = {};
    guildData.forEach((player) => {
      playerSet.add(player.name);
      playerCareers[player.name] = player.career;
    });

    const careers = [];
    playerSet.forEach((player) => {
      //console.log("player", player, playerCareers[player]);
      careers.push(playerCareers[player]);
    });

    let player = "";
    if (careers.length == 1) {
      player = playerSet.values().next().value;
    }

    //return careerset as array
    return { careers, player };
  };

  const orderWidth = (i) => {
    if (i == 0) {
      return `${Math.round((zone1OrderTotalKills / zone1TotalKills) * 100)}%`;
    }
    return `${Math.round((zone2OrderTotalKills / zone2TotalKills) * 100)}%`;
  };

  const destroWidth = (i) => {
    if (i == 0) {
      return `${Math.round((zone1DestTotalKills / zone1TotalKills) * 100)}%`;
    }
    return `${Math.round((zone2DestTotalKills / zone2TotalKills) * 100)}%`;
  };

  // return top 2 zones with list of kills per guild, //use div and flexbox to display
  return (
    <div className="main">
      <div className="container mx-auto max-w-5xl">
        <h2 className="font-bold my-5 text-lg md:flex justify-between space-y-2 md:space-y-0">
          <div>T4 RvR Latest ~{requiredKills} Kills or ~60 minutes</div>
          <div>
            Altdorf Time:{" "}
            {new Date().toLocaleTimeString("en-US", {
              timeZone: "Europe/Paris",
            })}{" "}
            {new Date().toLocaleDateString()}{" "}
          </div>
        </h2>
        <div className="grid md:grid-cols-2 gap-20">
          {sortedZoneKills.slice(0, 2).map((zone, i) => (
            <div key={zone[0]} className="border border-gray-600">
              <div className="font-bold text-lg space-y-4 bg-rose-950 px-4 pt-3 pb-6 mb-4 justify-between border-b border-gray-600">
                <div className="text-[19px] flex mx-auto w-content justify-around">
                  <div>{zone[0]}</div>
                  <div> {i == 0 ? zone1TotalKills : zone2TotalKills} kills</div>
                </div>
                <div className="flex justify-around space-x-10">
                  <div className="flex flex-cols">
                    <span className="text-blue-500 text-center">
                      Order{" "}
                      {i == 0 ? zone1OrderTotalKills : zone2OrderTotalKills}
                    </span>
                  </div>
                  <div className="flex h-4 w-full  relative top-3">
                    <div
                      className="bg-blue-500 h-6 text-white text-right pr-1"
                      style={{ width: orderWidth(i) }}
                    >
                      {orderWidth(i)}
                    </div>
                    <div
                      className="bg-red-500 h-6 text-white text-left pl-1"
                      style={{ width: destroWidth(i) }}
                    >
                      {destroWidth(i)}
                    </div>
                  </div>
                  <div className="flex flex-cols">
                    <span className="text-red-500 text-center">
                      Destro{" "}
                      {i == 0 ? zone1DestTotalKills : zone2DestTotalKills}
                    </span>
                  </div>
                </div>
              </div>

              {zone[1].slice(0, 10).map((guild) => (
                <div
                  key={guild[0]}
                  className={`my-1 grid grid-cols-8 px-2 py-1 text-sm ${getColor(
                    guild
                  )}`}
                >
                  <div className="col-span-4">{stripRealm(guild[0])}</div>
                  <div className="col-span-3 flex relative">
                    {getGuildCareerCount(zone[0], guild)
                      .careers.sort()
                      .map((career) => {
                        return (
                          <div key={career} className="w-4 top-1.5 relative">
                            <CareerIcon career={career} />
                          </div>
                        );
                      })}

                    <div className="ml-1 text-xs text-gray-500  top-1.5 relative">
                      {getGuildCareerCount(zone[0], guild).player}
                    </div>
                  </div>
                  <div className="text-center">{guild[1]}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Snapshot;
