import { gql } from "@apollo/client";

export const killsQuery = gql`
  query GetLatestKills(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $where: KillFilterInput
  ) {
    kills(
      first: $first
      last: $last
      before: $before
      after: $after
      where: $where
    ) {
      nodes {
        id
        time
        position {
          zone {
            id
            name
            __typename
          }
          __typename
        }
        scenario {
          id
          name
          __typename
        }
        victim {
          level
          renownRank
          character {
            id
            career
            name
            __typename
          }
          guild {
            id
            name
            realm
            __typename
          }
          __typename
        }
        attackers {
          level
          renownRank
          damagePercent
          character {
            id
            career
            name
            __typename
          }
          guild {
            id
            name
            realm
            heraldry {
              emblem
              pattern
              color1
              color2
              shape
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
        __typename
      }
      __typename
    }
  }
`;
