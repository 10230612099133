const WEEK_FROM = 2023.04;

// current year & week
const year = new Date().getFullYear();
const currentDate = new Date();
const startDate = new Date(currentDate.getFullYear(), 0, 1);
const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

const weekNumber = Math.ceil(days / 7);

const week = weekNumber;
const WEEK_TO = (year + week / 100).toFixed(2);

const n_weeks = 52 + weekNumber;
const WEEKS_ARRAY = [...Array(n_weeks).keys()].map((i) => {
  if (i < 52) {
    return {
      value: (year - 1 + (i + 1) / 100).toFixed(2),
      label: "Week " + (i + 1),
    };
  }
  return {
    value: (year + (i - 52 + 1) / 100).toFixed(2),
    label: "Week " + (i - 52 + 1),
  };
});

console.log(WEEKS_ARRAY);

const ORDER = [
  "SLAYER",
  "WHITE_LION",
  "WITCH_HUNTER",
  //
  "BRIGHT_WIZARD",
  "ENGINEER",
  "SHADOW_WARRIOR",
  //
  "IRONBREAKER",
  "SWORDMASTER",
  "KNIGHT",
  //
  "ARCHMAGE",
  "RUNE_PRIEST",
  "WARRIOR_PRIEST",
];

const DESTRO = [
  "CHOPPA",
  "MARAUDER",
  "WITCH_ELF",

  "SORCERER",
  "MAGUS",
  "SQUIG_HERDER",

  "BLACKGUARD",
  "BLACK_ORC",
  "CHOSEN",

  "SHAMAN",
  "ZEALOT",
  "DISCIPLE",
];

const ORDER_MDPS = ["SLAYER", "WHITE_LION", "WITCH_HUNTER"];
const DESTRO_MDPS = ["CHOPPA", "MARAUDER", "WITCH_ELF"];
const ORDER_RDPS = ["BRIGHT_WIZARD", "ENGINEER", "SHADOW_WARRIOR"];
const DESTRO_RDPS = ["SORCERER", "MAGUS", "SQUIG_HERDER"];

const DPS = [...ORDER_MDPS, ...ORDER_RDPS, ...DESTRO_MDPS, ...DESTRO_RDPS];
const DESTRO_TANKS = ["BLACKGUARD", "BLACK_ORC", "CHOSEN"];
const ORDER_TANKS = ["IRONBREAKER", "SWORDMASTER", "KNIGHT"];
const TANKS = [...ORDER_TANKS, ...DESTRO_TANKS];

const ORDER_DPS = [...ORDER_MDPS, ...ORDER_RDPS];
const DESTRO_DPS = [...DESTRO_MDPS, ...DESTRO_RDPS];

const CAREER_NAME_MAP = {
  IRON_BREAKER: "IRONBREAKER",
  SWORD_MASTER: "SWORDMASTER",
  KNIGHT_OF_THE_BLAZING_SUN: "KNIGHT",
  BLACK_GUARD: "BLACKGUARD",
  DISCIPLE_OF_KHAINE: "DISCIPLE",
};

export {
  weekNumber,
  WEEK_TO,
  WEEK_FROM,
  ORDER,
  DESTRO,
  ORDER_MDPS,
  ORDER_RDPS,
  DESTRO_RDPS,
  DESTRO_MDPS,
  DPS,
  TANKS,
  DESTRO_TANKS,
  ORDER_TANKS,
  ORDER_DPS,
  DESTRO_DPS,
  CAREER_NAME_MAP,
  WEEKS_ARRAY,
};
