import "./App.css";
import Snapshot from "./components/snapshot";

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const pwd = urlParams.get("pwd");

  return <Snapshot />;
}

export default App;
